import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Modal,
  Col,
} from "reactstrap";
//Import React Hook Form and validators
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Import API and alert popups
import API from "api/api.js";
import { alert, alertSuccess, alertError } from "api/utils";

//Create validation schema
const schema = yup.object().shape({
  password: yup.string().required("Password is required!"),
});

function ModalChangePassword(props) {
  const { isOpen, toggle, populateAdmins, admin } = props;
  const defaultValues = {
    password: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  
  const doChangePassword = async (values) => {
    //Title, SubText, isLoading
    alert("Changing Password", null, true);
    try {
      const { status } = await API("POST", `admin/password/change/${admin._id}`, { id: admin._id, ...values });
      if (status === "success")
        alertSuccess("Password Changed!", null, async () => {
          //After closing success pop up, reset form and close modal
          reset();
          toggle();

          //Refresh admins
          await populateAdmins();
        });
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to change password", error.data.msg);
      } else alertError("Something unexpected happened!");
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: 700 }}
    >
      <div className="modal-header pb-2" style={{ borderBottom: "1px solid #F2F2F2" }}>
        <h5 className="modal-title">Change Password</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pb-0">
        <Form role="form" onSubmit={handleSubmit(doChangePassword)}>
          <FormGroup row>
            <Label for="password" sm={2}>
              New Password
            </Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.password?.message}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="password" sm={2}>
              Confirm Password
            </Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.password?.message}</p>
            </Col>
          </FormGroup>
          <div className="modal-footer">
            <Button type="submit" className="my-4" color="primary">
              Change Password
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ModalChangePassword;
