import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  ButtonGroup,
  Input,
  Form,
  Table,
} from "reactstrap";

import API from "api/api";
import ModalPayoutDetails from "./ModalPayloadDetails";
import { alert, alertSuccess, alertError } from "api/utils";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const schema = yup.object().shape({
  message: yup.string().required("Message is required!"),
});

function ViewLogs() {
  const { search } = useLocation();
  const history = useHistory();
  const [session, setSession] = React.useState();
  const [logs, setLogs] = React.useState([]);

  const [cart, setCart] = React.useState();

  const [showView, setShowView] = React.useState(false);
  const [viewPayout, setViewPayout] = React.useState([]);

  const getLogs = async () => {
    let params = new URLSearchParams(search);
    let vendingId = params.get("id");
    let sessionId = params.get("sessionId");

    const { status, data } = await API("GET", `/logs/machine/${vendingId}/${sessionId}`);
    if (status === "success") {
      setSession({
        vendingId: data.vendingId,
        orderId: data.orderId,
        sessionId: data.sessionId,
        status: data.status,
      });
      setLogs(data.logs);
      let cart = data.logs.find((item) => {
        return "attach" in item;
      });
      console.log(cart);
      setCart({ items: cart.cart, totalPrice: cart.totalPrice });
    }
  };

  const defaultValues = {
    message: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const saveMessage = async (values) => {
    let params = new URLSearchParams(search);
    let sessionId = params.get("sessionId");
    alert("Saving Message", null, true);
    try {
      const createSave = {
        message: values.message,
      };
      const { status } = await API("POST", `logs/create/${sessionId}`, createSave);
      if (status === "success")
        alertSuccess("Save message Success!", null, async () => {
          reset();
          await getLogs();
        });
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to save message", error.data.msg);
      } else alertError("Something unexpected happened!");
    }
  };

  React.useEffect(() => {
    async function init() {
      await getLogs();
    }
    init();
  }, []);

  const openViewModal = (logs) => {
    setViewPayout(logs);
    setShowView(true);
  };

  return (
    <>
      <div className="header header-dark bg-info px-3 pb-6 content__title content__title--calendar">
        <Row className="py-3 align-items-center">
          <Col sm="12" lg="3" className="mb-3">
            <div className="header-body ">
              <Button
                className="btn-gray"
                color="default"
                size="sm"
                onClick={() => history.push("/admin/logs")}
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-chevron-left" />
                </span>
              </Button>
              <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                Transaction Logs
              </h6>
            </div>
          </Col>
          <Col sm="12" lg="8">
            <Form role="form" onSubmit={handleSubmit(saveMessage)}>
              <Row>
                <Col sm="12" lg="10" className="mb-3">
                  <Controller
                    control={control}
                    name="message"
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <Input
                        type="text"
                        placeholder="Message"
                        name={name}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  ></Controller>
                </Col>
                <Col sm="12" lg="2" className="mb-3">
                  <div className="pr-4">
                    <Button
                      type="submit"
                      className="btn-icon btn-neutral"
                      color="default"
                      size="md"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-save" />
                      </span>
                      <span className="btn-inner--text">Save</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody>
                <Table responsive bordered className="summary-table" size="sm">
                  <thead>
                    {session && (
                      <>
                        <tr>
                          <th colSpan="1" className="td-bold">
                            Order Serial Number
                          </th>
                          <td colSpan="4">{session.orderId || "Pending / NA"}</td>
                        </tr>
                        <tr>
                          <th colSpan="1" className="td-bold">
                            Card ID
                          </th>
                          <td colSpan="4">{session.sessionId || "Pending / NA"}</td>
                        </tr>
                        <tr>
                          <th colSpan="1" className="td-bold">
                            Vending Machine ID
                          </th>
                          <td colSpan="4">{session.vendingId || "Pending / NA"}</td>
                        </tr>
                        <tr>
                          <th colSpan="1" className="td-bold">
                            Status
                          </th>
                          <td colSpan="4">{session.status}</td>
                        </tr>
                      </>
                    )}
                    {cart && (
                      <>
                        <tr>
                          <th className="td-bold" colSpan="5">
                            Order Details
                          </th>
                        </tr>
                        <tr>
                          <th className="td-bold">No</th>
                          <th className="td-bold">Product Name</th>
                          <th className="td-bold">Unit Price</th>
                          <th className="td-bold">QTY</th>
                          <th className="td-bold">Total Price</th>
                        </tr>
                      </>
                    )}
                  </thead>
                  {cart && (
                    <tbody>
                      {cart.items.map((item, index) => {
                        return (
                          <tr>
                            <td scope="row">{index + 1}</td>
                            <td>{item.productName}</td>
                            <td>{`$${item.unitPrice}`}</td>
                            <td>{item.qty}</td>
                            <td>{`$${(parseFloat(item.unitPrice) * item.qty).toFixed(2)}`}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th colSpan="4" className="td-bold text-right">
                          Total Price
                        </th>
                        <td colSpan="1">{`$${cart.totalPrice}`}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </CardBody>
            </Card>
            <Card>
              <ToolkitProvider
                data={logs}
                keyField="_id"
                columns={[
                  {
                    dataField: "timestamp",
                    text: "Date Time",
                    sort: true,
                  },
                  {
                    dataField: "message",
                    text: "Action",
                    sort: true,
                  },
                  {
                    dataField: "logdetails",
                    text: "Log Details",
                    isDummyField: true,
                    sort: true,
                    formatter: (cell, row) => {
                      //row will give you the data in this row.
                      return (
                        <ButtonGroup>
                          <Button
                            id={cell}
                            className="btn-blue"
                            style={{ marginRight: 10 }}
                            onClick={(e) => {
                              e.preventDefault();
                              openViewModal(row);
                            }}
                            size="sm"
                          >
                            VIEW
                          </Button>
                        </ButtonGroup>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row>
                      <Col xs={12} sm={6}>
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalPayoutDetails
        logs={viewPayout}
        isOpen={showView}
        toggle={() => setShowView(!showView)}
        getLogs={getLogs}
      />
    </>
  );
}

export default ViewLogs;
