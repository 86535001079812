import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import { Button, Card, Container, Row, Col, ButtonGroup } from "reactstrap";

import API from "api/api";
import ModalCreateMachine from "./ModalCreateMachine";
import ModalEditMachine from "./ModalEditMachine";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function MachineList() {
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [targetMachine, setTargetMachine] = React.useState();
  const [machines, setMachines] = React.useState([]);

  const populateMachine = async () => {
    const { status, data } = await API("GET", "/machine/list");
    if (status === "success") {
      const { machines } = data;
      setMachines(machines);
    }
  };

  React.useEffect(() => {
    async function init() {
      await populateMachine();
    }
    init();
  }, []);

  const openEditModal = (machines) => {
    setTargetMachine(machines);
    setShowEdit(true);
  };

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Vending Machine
                </h6>
              </Col>
              <Col>
                <div className="float-right pr-4">
                  <Button
                    className="btn-icon btn-neutral"
                    color="default"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCreate(true);
                    }}
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">Create Vending Machine</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={machines}
                keyField="_id"
                columns={[
                  {
                    dataField: "vendingId",
                    text: "Vending Id",
                    sort: true,
                  },
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "location",
                    text: "Location",
                    sort: true,
                  },
                  {
                    dataField: "company",
                    text: "Company",
                    sort: true,
                  },
                  {
                    dataField: "actions",
                    text: "Action",
                    isDummyField: true,
                    sort: true,
                    formatter: (cell, row) => {
                      return (
                        <ButtonGroup>
                          <Button
                            id={cell}
                            className="btn-blue"
                            style={{ marginRight: 10 }}
                            onClick={(e) => {
                              e.preventDefault();
                              openEditModal(row);
                            }}
                            size="sm"
                          >
                            <span className="">
                              <i className="fas fa-pencil-alt" />
                            </span>
                          </Button>
                        </ButtonGroup>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row>
                      <Col xs={12} sm={6}>
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      defaultSorted={[{ dataField: "vendingId", order: "asc" }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalCreateMachine
        isOpen={showCreate}
        toggle={() => setShowCreate(!showCreate)}
        populateMachine={populateMachine}
      />
      <ModalEditMachine
        machines={targetMachine}
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        populateMachine={populateMachine}
      />
    </>
  );
}

export default MachineList;
