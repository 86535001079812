import axios from "axios";

const endpoint = "https://backend.easym.tech";
// const endpoint = "http://localhost:8000";

const instance = axios.create({ baseURL: endpoint });

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // if (error.response.status === 403) {
    //   window.location = "/";
    //   return;
    // }

    return Promise.reject(error);
  }
);

const API = (method, url, data, options) => {
  return instance({ method, url, data, withCredentials: true, ...options });
};

export default API;
