import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { Button, Card, Container, Row, Col, CardBody } from "reactstrap";
import API from "api/api";
import ModalEditStatus from "./ModalEditStatus";
import Moment from "react-moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const statuses = [
  { value: "New", label: "New" },
  { value: "Unpaid(retrying)", label: "Unpaid" },
  { value: "Order Paid", label: "Order Paid" },
  { value: "Order Expired", label: "Order Expired" },
  { value: "Unpaid(expired)", label: "Unpaid (expired)" },
];

function MachineLogsList() {
  const { search } = useLocation();
  const history = useHistory();
  const [vendingMachines, setVendingMachines] = React.useState([]);
  const [selectedMachines, setSelectedMachines] = React.useState([]);

  const [sessionLogs, setSessionLogs] = React.useState([]);

  const [dates, setDates] = React.useState([moment().startOf("d"), moment().endOf("d")]);

  const [selectedStatus, setSelectedStatus] = React.useState([]);

  const [showEdit, setShowEdit] = React.useState(false);
  const [targetLogs, setTargetLogs] = React.useState();

  const [totalTransaction, setTotalTransaction] = React.useState("0.00");

  // Create 3 state variables
  // 1) vendingMachines - List of vending machines available for filter
  // 2) selectedMachines - Selected vending machines to filter logs
  // 3) sessionLogs - List of logs (value to be passed into the table)

  // this use effect only triggers on initial page load - so should run functions that required on initial load (Don't need to load all logs initially)
  const getVendingMachine = async () => {
    const { status, data } = await API("GET", "/logs/machines");
    if (status === "success") {
      const { machines } = data;
      setVendingMachines(
        machines.map((i) => ({
          value: i.vendingId,
          label: i.name,
        }))
      );
    }
  };

  const populateLogs = async () => {
    let filter = {};
    if (selectedMachines && selectedMachines.length > 0) {
      filter.machines = selectedMachines.map((i) => i.value);
    }
    if (dates && dates.length > 0) {
      filter.startDate = dates[0];
      filter.endDate = dates[1];
    }
    if (selectedStatus && selectedStatus.length > 0) {
      filter.status = selectedStatus.map((i) => i.value);
    }

    //Check that there are filters selected
    const { status, data } = await API("POST", "/logs/filter", filter);
    if (status === "success") {
      const { logs } = data;
      setSessionLogs(logs);
    }
  };

  React.useEffect(() => {
    async function init() {
      // Populate list of vending machines
      await getVendingMachine();
    }
    init();
  }, []);

  React.useEffect(() => {
    let params = new URLSearchParams(search);
    let status = params.get("status");
    if (status) {
      if (status === "OU") setSelectedStatus(statuses.filter((i) => i.value.includes("Unpaid")));
      else if (status === "OP")
        setSelectedStatus(statuses.filter((i) => i.value.includes("Order Paid")));
    } else setSelectedStatus();
  }, [search]);

  React.useEffect(() => {
    const totalInCents = sessionLogs.reduce((a, b) => {
      if (b.amountInCents !== undefined) {
        a += b.amountInCents;
      }
      return a;
    }, 0);
    setTotalTransaction((totalInCents / 100).toFixed(2));
  }, [sessionLogs]);

  React.useEffect(() => {
    async function getLogs() {
      await populateLogs();
    }
    getLogs();
  }, [selectedMachines, dates, selectedStatus]);

  // onChange function to set selectedMachines
  const handleChange = (e) => {
    setSelectedMachines(e);
  };

  const handleChangeDate = (newDate) => {
    setDates(newDate);
  };

  const handleChangeStatus = (e) => {
    setSelectedStatus(e);
  };

  const viewLogs = (machine) =>
    history.push(`/admin/logs-view?id=${machine.vendingId}&sessionId=${machine.sessionId}`);

  const openEditModal = (logs) => {
    setTargetLogs(logs);
    setShowEdit(true);
  };

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid className="pb-4">
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="12">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Transactions
                </h6>
              </Col>
              <Col className="mt-4" lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="mb-3">
                        <Select
                          isMulti={true}
                          placeholder="Select Vending Machine"
                          options={vendingMachines}
                          onChange={(e) => handleChange(e)}
                          value={selectedMachines}
                        />
                      </Col>
                      <Col className="mb-3">
                        <DateRangePicker
                          onChange={(e) => handleChangeDate(e)}
                          value={dates}
                          format="dd/MM/yyyy"
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                        />
                      </Col>
                      <Col className="mb-3">
                        <Select
                          isMulti={true}
                          placeholder="Select Status"
                          options={statuses}
                          onChange={(e) => handleChangeStatus(e)}
                          value={selectedStatus}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card>
              <div>
                <h6 className="p-3 fullcalendar-title h2 d-inline-block mb-0">
                  No of Transactions: {sessionLogs.length}
                </h6>
                <h6 className="p-3 fullcalendar-title h2 d-inline-block mb-0">
                  Total Transaction Amount: ${totalTransaction}
                </h6>
              </div>
              <ToolkitProvider
                data={sessionLogs}
                keyField="_id"
                exportCSV
                columns={[
                  {
                    dataField: "doorCode",
                    text: "Vending Id",
                    sort: true,
                  },
                  {
                    dataField: "sessionId",
                    text: "Session ID",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    formatter: (cell) => {
                      const statusLabel = statuses.find((i) => i.value === cell);
                      return statusLabel ? statusLabel.label : cell;
                    },
                  },
                  {
                    dataField: "amountInCents",
                    text: "Amount",
                    sort: true,
                    formatter: (cell) => {
                      if (cell !== undefined) return `$${(parseInt(cell) / 100).toFixed(2)}`;
                    },
                  },
                  {
                    dataField: "phoneNo",
                    text: "Phone No",
                    sort: true,
                  },
                  {
                    dataField: "transactionId",
                    text: "Transaction ID",
                    sort: true,
                  },
                  {
                    dataField: "orderId",
                    text: "Order ID",
                    sort: true,
                  },
                  {
                    dataField: "createdAt",
                    text: "Create At",
                    sort: true,
                    csvFormatter: (cell) => {
                      return moment(cell).format("DD/MM/YYYY HH:mm:ss");
                    },
                    formatter: (cell) => {
                      return <Moment format="DD/MM/YYYY HH:mm:ss">{cell}</Moment>;
                    },
                  },
                  {
                    dataField: "actions",
                    text: "Action",
                    isDummyField: true,
                    sort: true,
                    csvExport: false,
                    formatter: (cell, row) => {
                      return (
                        <>
                          <Button
                            id={`view-logs-${row.vendingId}&${row.sessionId}`}
                            className="btn-blue"
                            style={{ marginRight: 10 }}
                            onClick={(e) => {
                              e.preventDefault();
                              viewLogs(row);
                            }}
                            size="sm"
                          >
                            View Details
                          </Button>
                          {row.status === "Unpaid(expired)" && (
                            <Button
                              className="btn-blue"
                              style={{ marginRight: 10 }}
                              onClick={(e) => {
                                e.preventDefault();
                                openEditModal(row);
                              }}
                              size="sm"
                            >
                              Mark Paid
                            </Button>
                          )}
                        </>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row>
                      <Col xs={12} sm={6}>
                        <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                        <div className="mx-4">
                          <ExportCSVButton {...props.csvProps}>Export</ExportCSVButton>
                        </div>
                      </Col>
                    </Row>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalEditStatus
        logs={targetLogs}
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        populateLogs={populateLogs}
      />
    </>
  );
}

export default MachineLogsList;
