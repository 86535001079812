import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import API from "api/api.js";
import { alert, alertError, closeAlert } from "api/utils";

const schema = yup.object().shape({
  username: yup.string().required("Username is required!"),
  password: yup.string().required("Password is required!"),
});

function Login(props) {
  const history = useHistory();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const doLogin = async (values) => {
    alert("Signing In", null, true);
    try {
      const { status, data } = await API("POST", "/admin/login", values);
      closeAlert();
      if (status === "success") {
        const {
          user: { _id, username },
        } = data;
        localStorage.setItem("user", JSON.stringify({ _id, username }));
        history.push("/admin");
      }
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to login", error.data.msg);
      } else alertError("Something unexpected happened!");
    }
  };

  return (
    <>
      <div className="login-container bg-gradient-login py-7 py-lg-8 pt-lg-2">
        <Container className="mt--6 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-blue-side border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="header-body text-center mb-4">
                    <Row className="justify-content-center">
                      <img
                        alt="..."
                        src={require("assets/img/brand/logo-easy.png").default}
                        width="25%"
                      />
                    </Row>
                  </div>
                  <div className="header-body mb-4">
                    <Row className="justify-content-center">
                      <h1 className="text-white text-center">EASY M Order Management</h1>
                    </Row>
                  </div>
                  <Form role="form" onSubmit={handleSubmit(doLogin)}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedEmail,
                      })}
                    >
                      <Controller
                        control={control}
                        name="username"
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="pl-2"
                              placeholder="Username"
                              type="text"
                              name={name}
                              onFocus={() => setfocusedEmail(true)}
                              onBlur={() => {
                                onBlur();
                                setfocusedEmail(false);
                              }}
                              onChange={onChange}
                              value={value}
                            />
                          </InputGroup>
                        )}
                      ></Controller>
                      <p className="mt-2 text-danger">{errors.username?.message}</p>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: focusedPassword,
                      })}
                    >
                      <Controller
                        control={control}
                        name="password"
                        render={({ field: { onChange, onBlur, value, name } }) => (
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="pl-2"
                              placeholder="Password"
                              type="password"
                              name={name}
                              onFocus={() => setfocusedPassword(true)}
                              onBlur={() => {
                                onBlur();
                                setfocusedPassword(false);
                              }}
                              onChange={onChange}
                              value={value}
                            />
                          </InputGroup>
                        )}
                      ></Controller>
                      <p className="mt-2 text-danger">{errors.password?.message}</p>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                      <label className="custom-control-label" htmlFor=" customCheckLogin">
                        <span className="text-white">Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" type="submit">
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
