import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  Modal,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
//Import React Hook Form and validators
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Import API and alert popups
import API from "api/api.js";
import { alert, alertSuccess, alertError } from "api/utils";

//Create validation schema
const schema = yup.object().shape({
  message: yup.string().required("Transaction Details are required!"),
});

function ModalEditStatus(props) {
  const { isOpen, toggle, logs, populateLogs } = props;
  const defaultValues = {
    message: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  // React.useEffect(() => {
  //   if (logs) {
  //     setValue("status", logs.status);
  //   }
  // }, [logs]);

  const EditStatus = async (values) => {
    //Title, SubText, isLoading
    alert("Editing Status", null, true);
    try {
      const editStatus = {
        id: logs._id,
        sessionId: logs.sessionId,
        status: logs.status,
        message: values.message,
      };
      const { status } = await API("POST", `/logs/mark-paid/${editStatus.sessionId}`, editStatus);
      if (status === "success")
        alertSuccess("Status Edited!", null, async () => {
          //After closing success pop up, reset form and close modal
          reset();
          toggle();

          await populateLogs();
        });
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to edit Status", error.data.msg);
      } else alertError("Something unexpected happened!");
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: 700 }}
    >
      <div className="modal-header pb-2" style={{ borderBottom: "1px solid #F2F2F2" }}>
        <h5 className="modal-title">Mark Paid</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body pb-0">
        <Form role="form" onSubmit={handleSubmit(EditStatus)}>
          <FormGroup row>
            <Label for="status" sm={4}>
              Transaction Details
            </Label>
            <Col sm={8}>
              <Controller
                control={control}
                name="message"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="textarea"
                    rows="4"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.message?.message}</p>
            </Col>
          </FormGroup>
          <div className="modal-footer">
            <Button type="submit" className="my-4" color="primary">
              Mark Paid
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ModalEditStatus;
