import AdminList from "views/pages/manageadmin/AdminList";
import MachineList from "views/pages/machine/MachineList";
import ViewLogs from "views/pages/machinelogs/ViewLogs";
import MachineLogsList from "views/pages/machinelogs/MachineLogsList";

const routes = [
  {
    path: "/logs",
    name: "Transactions",
    icon: "fas fa-file-invoice-dollar text-white",
    component: MachineLogsList,
    layout: "/admin",
    collapse: true,
    state: "transactionCollapse",
    views: [
      {
        path: "/logs",
        name: "Completed",
        miniName: "OP",
        filter: "OP",
        component: MachineLogsList,
        layout: "/admin",
      },
      {
        path: "/logs",
        name: "Unpaid",
        miniName: "OU",
        filter: "OU",
        component: MachineLogsList,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/machine",
    name: "Vending Machines",
    icon: "fas fa-store text-white",
    component: MachineList,
    layout: "/admin",
  },
  {
    path: "/logs-view",
    name: "View Logs",
    icon: "ni ni-single-02 text-white",
    component: ViewLogs,
    layout: "/admin",
    notSideBar: true,
  },
  {
    path: "/admin-list",
    name: "Manage Admin",
    icon: "fas fa-user-cog text-white",
    component: AdminList,
    layout: "/admin",
  },
];

export default routes;
