import React from "react";
import { Modal, Button } from "reactstrap";
import ReactJson from "react-json-view";

function ModalPayloadDetails(props) {
  const { logs, isOpen, toggle } = props;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: 700 }}
    >
      <div className="modal-header pb-1">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <h5 className="modal-title d-flex justify-content-center pb-3">Payout Details</h5>
        <div>
          <ReactJson
            src={logs}
            collapseStringsAfterLength={50}
            displayDataTypes={false}
            displayObjectSize={false}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button type="button" onClick={toggle} className="my-4" color="primary">
          Close
        </Button>
      </div>
    </Modal>
  );
}
export default ModalPayloadDetails;
