import React from "react";
import { Button, FormGroup, Form, Input, Label, Modal, Col } from "reactstrap";

//Import React Hook Form and validators
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//Import API and alert popups
import API from "api/api.js";
import { alert, alertSuccess, alertError } from "api/utils";

//Create validation schema
const schema = yup.object().shape({
  vendingId: yup.string().required("Vending ID is required!"),
  name: yup.string().required("Name is required!"),
  location: yup.string().required("Location is required!"),
  company: yup.string().required("Company is required!"),
});

function ModalEditMachine(props) {
  const { machines, isOpen, toggle, populateMachine } = props;
  const defaultValues = {
    vendingId: "",
    name: "",
    location: "",
    company: "",
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (machines) {
      setValue("vendingId", machines.vendingId);
      setValue("name", machines.name);
      setValue("location", machines.location);
      setValue("company", machines.company);
    }
  }, [machines]);

  const editMachine = async (values) => {
    // Title, Subtext, isLoading
    alert("Editing Vending Machine", null, true);
    try {
      const editData = {
        id: machines._id,
        vendingId: values.vendingId,
        name: values.name,
        location: values.location,
        company: values.company,
      };

      const { status } = await API(
        "POST",
        `/machine/edit/${editData.vendingId}`,
        editData
      );
      if (status === "success")
        alertSuccess("Machine Edited!", null, async () => {
          // After closing success pop up, reset form and close modal
          reset();
          toggle();

          // refresh Machine
          await populateMachine();
        });
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to create Course", error.data.msg);
      } else alertError("Something unexpected happened");
    }
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: 700 }}
    >
      {machines && (
      <div
        className="modal-header pb-2"
        style={{ borderBottom: "1px solid #F2F2F2" }}
      >
        <h5 className="modal-title">Edit Vending Machine ({machines.vendingId})</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      )}
      <div className="modal-body pb-0">
        <Form role="form" onSubmit={handleSubmit(editMachine)}>
          <FormGroup row>
            <Label for="name" sm={2}>
              Name
            </Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.name?.message}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="location" sm={2}>
              Location
            </Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="location"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.location?.message}</p>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="company" sm={2}>
              Company
            </Label>
            <Col sm={10}>
              <Controller
                control={control}
                name="company"
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Input
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              ></Controller>
              <p className="mt-2 text-danger">{errors.company?.message}</p>
            </Col>
          </FormGroup>

          <div className="modal-footer">
            <Button type="submit" className="my-4" color="primary">
              Edit Vending Machine
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ModalEditMachine;
