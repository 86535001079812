import Swal from "sweetalert2";

const alert = (title, text, loading = false) => {
  Swal.fire({
    title,
    text,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showConfirmButton: false,
    willOpen: () => {
      if (loading) Swal.showLoading();
    },
  });
};

const alertSuccess = (title, text, callback) => {
  Swal.fire({
    icon: "success",
    title,
    text,
  }).then(() => {
    if (callback) callback();
  });
};

const alertWarning = (title, text, callback) => {
  Swal.fire({
    icon: "warning",
    title,
    text,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

const alertError = (title, text, callback) => {
  Swal.fire({
    icon: "error",
    title,
    text,
  }).then(() => {
    if (callback) callback();
  });
};

const closeAlert = () => {
  Swal.close();
};

export { alert, alertWarning, alertSuccess, alertError, closeAlert };
