import React from "react";
import { Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import Login from "views/pages/Login.js";

import { GuardProvider, GuardedRoute } from "react-router-guards";

import API from "api/api.js";

function App() {
  const isAuthenticated = async (to, from, next) => {
    if (to.match.path === "/") {
      const { status } = await API("GET", "/admin/authenticated");
      if (status === "success") next.redirect("/admin");
      else next();
    }

    next();
  };

  const requireAuth = async (to, from, next) => {
    if (to.meta.requireAuth) {
      const { status } = await API("GET", "/admin/authenticated");
      if (status === "success") next();
      else next.redirect("/");
    }
    next();
  };

  return (
    <GuardProvider guards={[isAuthenticated, requireAuth]}>
      <Switch>
        <GuardedRoute path="/" component={Login} exact />
        <GuardedRoute path="/admin" component={AdminLayout} meta={{ requireAuth: true }} />
        <Redirect from="*" to="/" />
      </Switch>
    </GuardProvider>
  );
}

export default App;
