import API from "api/api";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, Container, Row, Col, Button } from "reactstrap";
import ModalCreateAdmin from "./ModalCreateAdmin";
import ModalEditAdmin from "./ModalEditAdmin";
import ModalChangePassword from "./ModalChangePassword";
import { alert, alertWarning, alertSuccess, alertError } from "api/utils";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function AdminList() {
  const [showCreate, setShowCreate] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [targetAdmin, setTargetAdmin] = React.useState();
  const [admins, setAdmins] = React.useState([]);

  const populateAdmins = async () => {
    const { status, data } = await API("GET", "/admin/list");
    if (status === "success") {
      const { admins } = data;
      setAdmins(admins);
    }
  };

  React.useEffect(() => {
    async function init() {
      await populateAdmins();
    }
    init();
  }, []);

  const openEditModal = (admin) => {
    setTargetAdmin(admin);
    setShowEdit(true);
  };

  const openChangePassword = (admin) => {
    setTargetAdmin(admin);
    setChangePassword(true);
  };

  const deleteAdmin = (admin) => {
    try {
      alertWarning("Are you sure?", `Deleting ${admin.username}`, async () => {
        alert("Deleting Admin", null, true);
        const { status } = await API("DELETE", `/admin/${admin._id}`);
        if (status === "success") {
          alertSuccess("Admin deleted!", null, async () => {
            await populateAdmins();
          });
        }
      });
    } catch (err) {
      if (err.response) {
        const { data: error } = err.response;
        alertError("Unable to delete admin", error.data.msg);
      } else alertError("Something unexpected happened");
    }
  };

  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  Manage Admin
                </h6>
              </Col>
              <Col>
                <div className="float-right pr-4">
                  <Button
                    className="btn-icon btn-neutral"
                    color="default"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowCreate(true);
                    }}
                    size="sm"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">Create Admin</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <ToolkitProvider
                data={admins}
                keyField="_id"
                columns={[
                  {
                    dataField: "username",
                    text: "Username",
                    sort: true,
                  },
                  {
                    dataField: "actions",
                    text: "Action",
                    isDummyField: true,
                    sort: true,
                    formatter: (cell, row) => {
                      //row will give you the data in this row.
                      const { _id } = JSON.parse(localStorage.getItem("user"));
                      return (
                        <>
                          <Button
                            id={cell}
                            className="btn-blue"
                            onClick={(e) => {
                              e.preventDefault();
                              openEditModal(row);
                            }}
                            size="sm"
                          >
                            <span className="">
                              <i className="fas fa-pencil-alt pr-2" />
                              Edit Username
                            </span>
                          </Button>
                          <Button
                            id={cell}
                            className="btn-blue"
                            onClick={(e) => {
                              e.preventDefault();
                              openChangePassword(row);
                            }}
                            size="sm"
                          >
                            <span className="">
                              <i className="fas fa-pencil-alt pr-2" />
                              Change Password
                            </span>
                          </Button>
                          <Button
                            color="danger"
                            style={{ marginRight: 5 }}
                            onClick={(e) => {
                              e.preventDefault();
                              deleteAdmin(row);
                            }}
                            size="sm"
                            disabled={_id === row._id}
                          >
                            <span className="">
                              <i className="fas fa-trash pr-2" />
                              Delete Admin
                            </span>
                          </Button>
                        </>
                      );
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Row>
                      <Col xs={12} sm={6}>
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 pb-1"
                        >
                          <label>
                            Search:
                            <SearchBar
                              className="form-control-sm"
                              placeholder=""
                              {...props.searchProps}
                            />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
      <ModalCreateAdmin
        isOpen={showCreate}
        toggle={() => setShowCreate(!showCreate)}
        populateAdmins={populateAdmins}
      />
      <ModalEditAdmin
        isOpen={showEdit}
        toggle={() => setShowEdit(!showEdit)}
        admin={targetAdmin}
        populateAdmins={populateAdmins}
      />
      <ModalChangePassword
        isOpen={changePassword}
        toggle={() => setChangePassword(!changePassword)}
        admin={targetAdmin}
        populateAdmins={populateAdmins}
      />
    </>
  );
}

export default AdminList;
